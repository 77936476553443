import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { withTranslation } from 'react-i18next';
import { Button } from "react-bootstrap";
import FileSaver from 'file-saver';
import "../styles/LoginAdminStyle.css"
import { JsonToTable } from "react-json-to-table";

import 'semantic-ui-css/semantic.min.css';

// import { ReactEasyNotify, confirm } from 'react-easy-notify'
// import 'react-easy-notify/dist/index.css'


const options = {
    text: 'Do you want delete all entries from the database?',
    timeout: 5000,
    type: 'warning',
    title: 'warning',
    animationType: 'vibration',
    callback: function () {
        fetch(process.env.REACT_APP_TRUNCATE_URL, {
            method: 'DELETE',
            headers: {'Authorization': `Bearer ${localStorage.getItem('currentUser').replace("\"", "").slice(0,-1)}`}
        });  
    }
  }


class AdminComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dbContent: null,
            open: false
        };
        this.show = this.show.bind(this);


    }

    /**
     * Shows all player data from DB.
     */
    async show() {
        let data;
        await fetch(process.env.REACT_APP_PLAYER_URL)
            .then(res => res.json())
            .then(json => data = json);
        this.setState({ dbContent: data });
    }


    /**
     * Exports player data from DB.
     */
    export() {
        fetch(process.env.REACT_APP_PLAYER_URL)
            .then(res => res.json())
            .then(json => {
                FileSaver.saveAs(new File([JSON.stringify(json)], "data.json", { type: "application/json" }));
            })
    }

    render() {
        const { t } = this.props;
        return (
            <>
                {/* <ReactEasyNotify /> */}
                <div>
                    <table>
                        <tr>
                            <td>
                                <p>{t('showTable')}</p>
                                <Button variant="success" onClick={this.show}>{t('show')}</Button>
                            </td>
                            <td>
                                <p>{t('exportTable')}</p>
                                <Button variant="warning" onClick={this.export}>{t('export')}</Button>
                            </td>
                            <td>
                                <p>{t('dropTable')}</p>
                                {/* <Button variant="danger" onClick={() => confirm(options)}>{t('drop')}</Button> */}
                            </td>
                        </tr>
                    </table>
                </div>
                <div>
                    <JsonToTable json={this.state.dbContent} />
                </div>
            </>
        );
    }
}
export default withTranslation()(AdminComponent);