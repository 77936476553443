import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from "./TimerPartComponent";
import '../styles/TimerStyle.scss';
import { messageService } from '../service/ObservableService';

/**
 * @param seconds - the number of seconds to countup from
 * @param className - a class to assign to the wrapper component
 * @desc A Countdown components counts down from a given
 * number of seconds. (Maximum 1 hr)
 */
function Countup(props, { className = '' }) {

    const [localtries, setLocalTries] = useState(0);

    // sub messageService
    const subscription = messageService.getMessage().subscribe((message) => {
        if (message.value === 'triesSend') {
            sendState();
        } else if (message.value === 'triesIncrease') {
            setLocalTries(localtries + 1);

        }
    });

    /**
     * @desc Subtracts the local seconds by one
     * every second, until it reaches zero.
     */
    useEffect(() => {
        return () => {
            // unsub messageService
            subscription.unsubscribe();
        }
    });

    /**
     * @param num {int}
     * @desc Pads a number with 0's and returns
     * an array of integers.
     */
    function prettify(num) {
        return num.toString(10).padStart(2, "0").split("").map(val => parseInt(val, 10));
    }

    /**
     * This function rerenders the timerblock.
     * @param {*} num 
     * @returns 
     */
    function renderBlock(num) {
        num = prettify(num);
        return (
            <div className="bloc-time">
                <div className={"cards"}>
                    <Card number={num[0]} />
                    <Card number={num[1]} />
                </div>
            </div>
        );
    }

    /**
     * This method calls the updateTime method in the MasterComponent that will update the gameTime state.
     * After that it hides the timer.
     */
    function sendState() {
        props.updateTries(localtries);
        messageService.sendMessage('triesHide');
    }

    return (
        <div className={`countdown ${className}`}>
            {renderBlock(localtries)}
        </div>
    );
}

Countup.propTypes = {
    seconds: PropTypes.number
};

export default Countup;