import { withTranslation } from 'react-i18next';
import React from "react";
import "../styles/ProgressRingStyle.css"


class ProgressRingComponent extends React.Component {
    constructor(props) {
        super(props);

        const { radius, stroke  } = this.props;

        this.normalizedRadius = radius - stroke * 2;
        this.circumference = this.normalizedRadius * 2 * Math.PI;
    }

    render() {
        const { radius, stroke, progress, title, text, parts } = this.props;

        const strokeDashoffset = this.circumference - progress / parts * this.circumference;

        return (
            <div>
                <svg
                    height={radius * 2 + 40}
                    width={radius * 2 + 40}
                >
                    <g>
                        <text x="60" y="20" text-anchor="middle" class="title">{title}</text>
                        <circle
                            stroke="red"
                            fill="rgb(169, 177, 195)"
                            strokeWidth={stroke}
                            strokeDasharray={this.circumference + ' ' + this.circumference}
                            style={{ strokeDashoffset }}
                            stroke-width={stroke}
                            r={this.normalizedRadius}
                            cx={radius}
                            cy={radius}
                        ></circle>
                        <text x="60" y="105" text-anchor="middle" class="text">{text}</text>
                    </g>
                </svg>
            </div>
        );
    }
} export default withTranslation()(ProgressRingComponent);