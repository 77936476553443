import "../styles/MenuStyle.css";

import React, { Component } from "react";
import { Row, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { withTranslation } from "react-i18next";

class MenuComponent extends Component {
  /**
   * The changePage method calls its parent MasterComponent and pases the specific page
   * which was selected through the button onClick event in MenuComponent
   * @param {} nextPage page that was selected in onClick
   */
  changePage(nextPage) {
    this.props.handler(nextPage);
  }

  render() {
    const { t } = this.props;
    return (
      <div className="menu-wrapper">
        <div className="menu-content">
          <Row>
            <Button
              className="navigation-button"
              onClick={() => {
                this.changePage("gameMode");
              }}
            >
              {t("start_game")}
            </Button>
          </Row>
          <Row>
            <Button
              className="navigation-button"
              onClick={() => {
                this.changePage("statistics");
              }}
            >
              {t("show_statistics")}
            </Button>
          </Row>
          <Row>
            <Button
              className="navigation-button"
              onClick={() => {
                this.changePage("settings");
              }}
            >
              {t("settings")}
            </Button>
          </Row>
        </div>
      </div>
    );
  }
}
export default withTranslation()(MenuComponent);
