import "../styles/SettingsStyle.css";

import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { withTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
// import { notify, ReactEasyNotify } from "react-easy-notify";
// import "react-easy-notify/dist/index.css";

class SettingsComponent extends Component {
  /**
   * This method calls the updateCategories method in MasterComponent to set the current catagorie states
   * @param {} categories categorie states which are either true or false
   */
  saveCategories = (values) => {
    this.props.updateCategories(values.categories);
    // notify({
    //   type: "success",
    //   title: "success",
    //   status: true,
    //   timeout: 3000,
    //   message: "Your data has been saved!",
    //   position: "top-right",
    //   animationType: "pops-up",
    // });
  };

  render() {
    const { t } = this.props;

    const professions = t("card", { returnObjects: true });

    const fields = Object.keys(professions).map((key, i) => {
      return (
        <div>
          <Col>
            <Field
              className="checkbox"
              type="checkbox"
              name={`categories.${key}`}
            />
            <label>{professions[key].content.name}</label>
          </Col>
        </div>
      );
    });

    return (
      <div class="wrapper-settings">
        <Formik
          onSubmit={(values, actions) => {
            this.saveCategories(values);
            actions.setSubmitting(false);
          }}
          initialValues={{ categories: this.props.gameCategories }}
          enableReinitialize={true}
        >
          <Form id="container-settings">
            <Col>{fields}</Col>
            <Row>
              <Button id="save-button" className="button" type="submit">
                {t("save")}
              </Button>
            </Row>
          </Form>
        </Formik>
      </div>
    );
  }
}
export default withTranslation()(SettingsComponent);
