import "../styles/MenuStyle.css";

import React, { Component } from "react";
import { Container, Row, Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";

class GameModeComponent extends Component {
  /**
   * This method calls the handler in MasterComponent and passes the page to navigate
   * @param {*} nextPage page which should be shown next
   */
  changePage(nextPage) {
    this.props.handler(nextPage);
  }

  /**
   * This method calls updateGameMode in MasterComponent and calls changePage for navigation to the game
   * @param {*} mode mode which is selected through button onClick event
   */
  selectGameMode(mode) {
    this.props.updateGameMode(mode);
    this.changePage("game");
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="menu-wrapper">
          <div className="menu-content">
            <Row>
              <Button
                className="navigation-button"
                onClick={() => {
                  this.selectGameMode("easy");
                }}
              >
                {t("easy")}
              </Button>
            </Row>
            <Row>
              <Button
                className="navigation-button"
                onClick={() => {
                  this.selectGameMode("medium");
                }}
              >
                {t("medium")}
              </Button>
            </Row>
            <Row>
              <Button
                className="navigation-button"
                onClick={() => {
                  this.selectGameMode("hard");
                }}
              >
                {t("hard")}
              </Button>
            </Row>
          </div>
        </div>
      </>
    );
  }
}
export default withTranslation()(GameModeComponent);
