import "../styles/FormStyle.css";

import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import { Row, Col, Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import ProgressRingComponent from "./ProgressRingComponent";
import 'bootstrap/dist/css/bootstrap.min.css';
import blacklist from "../data/blacklist.json";
import Confetti from 'react-confetti';
import { notify, ReactEasyNotify } from 'react-easy-notify';
import 'react-easy-notify/dist/index.css';
import disableScroll from 'disable-scroll';
import { Container } from "react-bootstrap/lib/Tab";


class FormComponent extends Component {


    constructor(props) {
        super(props);
        const scoreFiltered = this.calculateScore(this.props.gameTries, this.props.gameTime) / 10;
        const score = Math.ceil(this.calculateScore(this.props.gameTries, this.props.gameTime));

        this.state = {
            progressTime: 0,
            progressTries: 0,
            progressScore: 0,
            progressMode: 0,
            scoreFiltered: scoreFiltered,
            score: score,
        };
        this.socket = props.socket;
        this.sendBroadcast = this.sendBroadcast.bind(this);
        disableScroll.off(); // re-enable scroll

    }

    /**
     * Checks if values are on the blacklist.
     * @param {*} values which are going to be validated
     */
    validate(values) {
        const wash = require('washyourmouthoutwithsoap');
        const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (values.firstName && values.lastName && values.email) {
            blacklist.words.forEach(function (e) {
                if (e === values.firstName.toLowerCase() || e === values.lastName.toLowerCase() || e === values.email.substring(0, values.email.lastIndexOf("@")).toLowerCase() || e === values.email.substring(values.email.lastIndexOf("@") + 1)) {
                    notify({
                        type: 'warning',
                        title: 'Warning',
                        status: true,
                        timeout: 3000,
                        message: "String " + e + " is on our Blacklist. Please change the entry or ask for help!",
                        position: 'top-right',
                        animationType: 'pops-up'
                    })
                    return false;
                }
            });
        } else if (!emailRegex.test(values.email)) {
            notify({
                type: 'warning',
                title: 'Warning',
                status: true,
                timeout: 3000,
                message: "The email " + values.email + " is invalid.",
                position: 'top-right',
                animationType: 'pops-up'
            })
            return false;
        }
        if (wash.check('de', values.firstName.toLowerCase()) || wash.check('en', values.firstName.toLowerCase()) || wash.check('fr', values.firstName.toLowerCase()) ||
            wash.check('de', values.lastName.toLowerCase()) || wash.check('en', values.lastName.toLowerCase()) || wash.check('fr', values.lastName.toLowerCase()) ||
            wash.check('de', values.email.toLowerCase()) || wash.check('en', values.email.toLowerCase()) || wash.check('fr', values.email.toLowerCase())) {
            notify({
                type: 'warning',
                title: 'Warning',
                status: true,
                timeout: 3000,
                message: "Please type in your real name or ask for help.",
                position: 'top-right',
                animationType: 'pops-up'
            })
            return false;
        }
        return true;
    }

    componentDidMount() {
        // emulating progress
        const intervalTime = setInterval(() => {
            this.setState({ progressTime: this.state.progressTime + 1 });
            if (this.state.progressTime === this.props.gameTime)
                clearInterval(intervalTime);
        }, 100);
        const intervalTries = setInterval(() => {
            this.setState({ progressTries: this.state.progressTries + 1 });
            if (this.state.progressTime === this.props.gameTries)
                clearInterval(intervalTries);
        }, 100);
        const intervalScore = setInterval(() => {
            this.setState({
                progressScore: this.state.progressScore + 1
            });
            if (this.state.progressScore === this.state.score)
                clearInterval(intervalScore);
        }, 1);
        const intervalMode = setInterval(() => {
            this.setState({ progressMode: this.state.progressMode + 10 });
            if (this.state.progressMode === 100)
                clearInterval(intervalMode);
        }, 100);
    }

    calculateScore = (tries, time) => {
        let points = 1000;
        let bonus = 200;
        if (tries === 0) {
            return points + bonus - 1.5 * time;

        } else {
            return points - (3 * Math.sqrt(tries) + 1.5 * time);
        }
    }

    submitPlayer(values) {
        fetch(process.env.REACT_APP_PLAYER_PUSH_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(values),
        })
            .then(response => response.json())
            .then(values => {
                console.log('Success:', values);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    sendBroadcast() {
        console.log("Emitting message with socket!")
        this.socket.emit('update', "leaderboard");
    }

    render() {
        const { t } = this.props;
        const mode = this.props.gameMode;
        return (
            <div id="wrapperForm">
                <Container>
                    <div>
                        <ReactEasyNotify />
                        <Confetti width={10000} height={1500} />
                        <div class="statistics">
                            <ProgressRingComponent title={t('time')} text={this.state.progressTime} radius={60} stroke={4} parts="100" progress={this.state.progressTime} />
                            <ProgressRingComponent title={t('tries')} text={this.state.progressTries} radius={60} stroke={4} parts="100" progress={this.state.progressTries} />
                            <ProgressRingComponent title={t('score')} text={this.state.progressScore} radius={60} stroke={4} parts="1000" progress={this.state.progressScore} />
                            <ProgressRingComponent title={t('gameMode')} text={mode} radius={60} stroke={4} parts="100" progress={this.state.progressMode} />
                        </div>

                        <Formik
                            initialValues={{
                                firstName: '',
                                lastName: '',
                                email: '',
                                accept: false
                            }}
                            onSubmit={(values, actions) => {
                                if (values.accept) {
                                    if (this.validate(values)) {
                                        this.submitPlayer({
                                            firstName: values.firstName,
                                            lastName: values.lastName,
                                            email: values.email,
                                            score: this.state.score,
                                            gameMode: this.props.gameMode
                                        })
                                        console.log("submitted => " + values);
                                        actions.setSubmitting(false);
                                        this.sendBroadcast();
                                        this.props.handler('menu');
                                    }

                                } else if (!values.accept) {
                                    notify({
                                        type: 'warning',
                                        title: 'Warning',
                                        status: true,
                                        timeout: 5000,
                                        message: t('mustAccept'),
                                        position: 'top-right',
                                        animationType: 'pops-up'
                                    })
                                } else {
                                    notify({
                                        type: 'warning',
                                        title: 'Warning',
                                        status: true,
                                        timeout: 3000,
                                        message: 'Something went wrong!',
                                        position: 'top-right',
                                        animationType: 'pops-up'
                                    })
                                }

                            }}
                        >
                            <Col>
                                {(
                                    <Form>
                                        <Row>
                                            <Col>
                                                <label>{t('firstName')}:</label>
                                            </Col>
                                            <Col>
                                                <Field id="form-input" className="m-3" name="firstName" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <label>{t('lastName')}:</label>
                                            </Col>
                                            <Col>
                                                <Field id="form-input" className="m-3" name="lastName" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <label>{t('mail')}:</label>
                                            </Col>
                                            <Col>
                                                <Field id="form-input" className="m-3" type="email" name="email" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Field className="m-3" type="checkbox" name="accept" />
                                            </Col>
                                            <Col>
                                                {t('terms')}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Button id="save-button" className="button" type="submit">{t('submit')}</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Col>
                        </Formik>
                    </div>
                </Container>
            </div >
        );
    }
}
export default withTranslation()(FormComponent);