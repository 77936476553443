// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.countdown {
  height: -moz-fit-content;
  height: fit-content;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-left: 10vw;
}
.countdown .bloc-time {
  text-align: center;
  display: flex;
  flex-direction: column;
}
.countdown .bloc-time .cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1vw;
}
.countdown .count-title {
  display: block;
  font: normal 0.94em "Lato";
  color: white;
  text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/styles/TimerStyle.scss"],"names":[],"mappings":"AACA;EACE,wBAAA;EAAA,mBAAA;EACA,aAAA;EACA,qCAAA;EACA,iBAAA;AAAF;AAEE;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;AAAJ;AAEI;EACE,aAAA;EACA,qCAAA;EACA,aAAA;AAAN;AAIE;EACE,cAAA;EACA,0BAAA;EACA,YAAA;EACA,yBAAA;AAFJ","sourcesContent":["// Countdown\n.countdown {\n  height: fit-content;\n  display: grid;\n  grid-template-columns: repeat(4, 1fr);\n  margin-left: 10vw;\n\n  .bloc-time {\n    text-align: center;\n    display: flex;\n    flex-direction: column;\n\n    .cards {\n      display: grid;\n      grid-template-columns: repeat(3, 1fr);\n      grid-gap: 1vw;\n    }\n  }\n\n  .count-title {\n    display: block;\n    font: normal 0.94em \"Lato\";\n    color: white;\n    text-transform: uppercase;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
