import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/StatisticStyles.css';

class StatisticsComponent extends Component {

    socket = null;

    constructor(props) {
        super(props);
        this.state = {
            scoreEasy: [],
            scoreMedium: [],
            scoreHard: []
        }
        this.socket = props.socket;
    }

    getData() {
        fetch(process.env.REACT_APP_SCORE_URL)
            .then(response => response.json()).catch(e => alert("No server connection. Error: " + e))
            .then(data => {
                const resEasy = data.boardEasy.sort((a, b) => a.score - b.score).reverse();
                const resMedium = data.boardMedium.sort((a, b) => a.score - b.score).reverse();
                const resHard = data.boardHard.sort((a, b) => a.score - b.score).reverse();
                this.setState({ scoreEasy: resEasy, scoreMedium: resMedium, scoreHard: resHard });
        }).catch(e => console.log(e));
    }

    componentDidMount() {
        this.getData();
        this.socket.on("updateLeaderboard",() => {
            console.log('updating board...');
            this.getData();
        });
    }

    transformScore(score) {
        return score.map((record, i) => {
            return (
                <>
                    <tr>
                        <td>
                            <strong>{i + 1}</strong>
                        </td>
                        <td>
                            <strong>{record.name}</strong>
                        </td>
                        <td>
                            <strong>{record.score}</strong>
                        </td>
                    </tr>
                </>
            )
        })
    }

    render() {
        const { t } = this.props;
        return (
            <div id="wrapperStatistics">
                <div class="grid">
                    <p class="styled-p">{t('easy')}</p>
                    <p class="styled-p">{t('medium')}</p>
                    <p class="styled-p">{t('hard')}</p>
                    <table class="styled-table">
                        <tr>
                            <th>#</th>
                            <th>{t('name')}</th>
                            <th>{t('score')}</th>
                        </tr>
                        {this.transformScore(this.state.scoreEasy)}
                    </table>
                    <table class="styled-table">
                        <tr>
                            <th>#</th>
                            <th>{t('name')}</th>
                            <th>{t('score')}</th>
                        </tr>
                        {this.transformScore(this.state.scoreMedium)}

                    </table>
                    <table class="styled-table">
                        <tr>
                            <th>#</th>
                            <th>{t('name')}</th>
                            <th>{t('score')}</th>
                        </tr>
                        {this.transformScore(this.state.scoreHard)}
                    </table>
                </div>
            </div>
        );
    }
}
export default withTranslation()(StatisticsComponent);