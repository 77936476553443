import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { withTranslation } from "react-i18next";
import "../styles/LoginAdminStyle.css";
import { Button } from "react-bootstrap";
import { PropTypes } from 'react'


import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { authenticationService } from "../service/authentication.service";

class LoginAdminComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="login-container">
        <Formik
          initialValues={{
            username: "",
            password: "",
          }}
          validationSchema={Yup.object().shape({
            username: Yup.string().required("Username is required"),
            password: Yup.string().required("Password is required"),
          })}
          onSubmit={({ username, password }, { setStatus, setSubmitting }) => {
            setStatus();
            authenticationService.login(username, password).then(
              (user) => {
                this.props.handler('admin');
              },
              (error) => {
                setSubmitting(false);
                setStatus(error);
              }
            );
          }}
          render={({ errors, status, touched, isSubmitting }) => (
            <div class="login-form">
              <Form>
                <div className="form-group">
                  <h1 htmlFor="username">Username</h1>
                  <Field
                    name="username"
                    type="text"
                    className={
                      "form-control" +
                      (errors.username && touched.username ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="username"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="form-group">
                  <h1 htmlFor="password">Password</h1>
                  <Field
                    name="password"
                    type="password"
                    className={
                      "form-control" +
                      (errors.password && touched.password ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="form-group">
                  <Button
                    type="submit"
                    class="login-button"
                    disabled={isSubmitting}
                  >
                    Login
                  </Button>
                </div>
                {status && <div className={"alert alert-danger"}>{status}</div>}
              </Form>
            </div>
          )}
        />
      </div>
    );
  }
}
export default withTranslation()(LoginAdminComponent);
