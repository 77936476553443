// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../res/background-blur.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid {
    display: grid;
    width: 100vw;
    grid-template-columns: 33% 33% 33%;
    grid-gap: 0.5vw;
}

.styled-table {
    color: white;
    border-collapse: collapse;
    font-family: sans-serif;
    box-shadow: 0 0 2vh rgba(0, 0, 0, 0.15);
}

th {
    background-color: #dc0000;
    color: white;
    text-align: center;
    font-weight: bold;
}

.styled-p {
    background-color: #dc0000;
    color: white;
    text-align: center;
    font-weight: bold;
}

td {
    text-align: center;
    font-weight: bolder;
}



tr:nth-child(even) {
    background-color: #dc0000c7;
}

tr:nth-child(odd) {
    background-color: #dc0000e3;
}

#wrapperStatistics {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center fixed;
    min-height: 100vh;
    background-size: cover;
    position: 'absolute';
    left: '50%';
    top: '50%';
    transform: 'translate(-50%, -50%)';
}`, "",{"version":3,"sources":["webpack://./src/styles/StatisticStyles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,kCAAkC;IAClC,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,uBAAuB;IACvB,uCAAuC;AAC3C;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;;;AAIA;IACI,2BAA2B;AAC/B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,0EAAoE;IACpE,iBAAiB;IACjB,sBAAsB;IACtB,oBAAoB;IACpB,WAAW;IACX,UAAU;IACV,kCAAkC;AACtC","sourcesContent":[".grid {\n    display: grid;\n    width: 100vw;\n    grid-template-columns: 33% 33% 33%;\n    grid-gap: 0.5vw;\n}\n\n.styled-table {\n    color: white;\n    border-collapse: collapse;\n    font-family: sans-serif;\n    box-shadow: 0 0 2vh rgba(0, 0, 0, 0.15);\n}\n\nth {\n    background-color: #dc0000;\n    color: white;\n    text-align: center;\n    font-weight: bold;\n}\n\n.styled-p {\n    background-color: #dc0000;\n    color: white;\n    text-align: center;\n    font-weight: bold;\n}\n\ntd {\n    text-align: center;\n    font-weight: bolder;\n}\n\n\n\ntr:nth-child(even) {\n    background-color: #dc0000c7;\n}\n\ntr:nth-child(odd) {\n    background-color: #dc0000e3;\n}\n\n#wrapperStatistics {\n    background: url(\"../res/background-blur.png\") no-repeat center fixed;\n    min-height: 100vh;\n    background-size: cover;\n    position: 'absolute';\n    left: '50%';\n    top: '50%';\n    transform: 'translate(-50%, -50%)';\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
