// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../res/background-game-blur.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid-container-small {
    display: grid;
    height: 100vh;
    width: 100vw;
    grid-template-rows: 20vw 20vw 20vw;
    grid-template-columns: 25vh 25vh 25vh 25vh;
    justify-content: space-evenly;


}

.grid-container-medium {
    display: grid;
    height: 100vh;
    width: 100vw;
    grid-template-rows: 15vw 15vw 15vw;
    grid-template-columns: 25vh 25vh 25vh 25vh;
    justify-content: space-evenly;
}

.grid-container-big {
    display: grid;
    width: 100vw;
    grid-template-rows: 15vw 15vw 15vw 15vw;
    grid-template-columns: 20vh 20vh 20vh 20vh 20vh;
    justify-content: space-evenly;
}


.cardSize-small {
    height: 30vh;
}

.cardSize-medium {
    height: 25vh;
}

.cardSize-big {
    height: 25vh;
}

.grid-item1 {
    text-align: center;
}

.game-wrapper {
background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center center fixed;
    height: 100vh;
    width: 100vw;
    background-size: cover;
}





`, "",{"version":3,"sources":["webpack://./src/styles/GameStyle.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,YAAY;IACZ,kCAAkC;IAClC,0CAA0C;IAC1C,6BAA6B;;;AAGjC;;AAEA;IACI,aAAa;IACb,aAAa;IACb,YAAY;IACZ,kCAAkC;IAClC,0CAA0C;IAC1C,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,uCAAuC;IACvC,+CAA+C;IAC/C,6BAA6B;AACjC;;;AAGA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;AACA,iFAAgF;IAC5E,aAAa;IACb,YAAY;IACZ,sBAAsB;AAC1B","sourcesContent":[".grid-container-small {\n    display: grid;\n    height: 100vh;\n    width: 100vw;\n    grid-template-rows: 20vw 20vw 20vw;\n    grid-template-columns: 25vh 25vh 25vh 25vh;\n    justify-content: space-evenly;\n\n\n}\n\n.grid-container-medium {\n    display: grid;\n    height: 100vh;\n    width: 100vw;\n    grid-template-rows: 15vw 15vw 15vw;\n    grid-template-columns: 25vh 25vh 25vh 25vh;\n    justify-content: space-evenly;\n}\n\n.grid-container-big {\n    display: grid;\n    width: 100vw;\n    grid-template-rows: 15vw 15vw 15vw 15vw;\n    grid-template-columns: 20vh 20vh 20vh 20vh 20vh;\n    justify-content: space-evenly;\n}\n\n\n.cardSize-small {\n    height: 30vh;\n}\n\n.cardSize-medium {\n    height: 25vh;\n}\n\n.cardSize-big {\n    height: 25vh;\n}\n\n.grid-item1 {\n    text-align: center;\n}\n\n.game-wrapper {\nbackground: url(\"../res/background-game-blur.png\") no-repeat center center fixed;\n    height: 100vh;\n    width: 100vw;\n    background-size: cover;\n}\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
