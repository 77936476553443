import "../styles/MainStyle.css";

import React, { Component } from "react";
import { Navbar, Nav, Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import i18next from "i18next";
import Select from "react-select";
import TimerComponent from "./TimerComponent";
import TriesComponent from "./TriesComponent";


import { messageService } from "../service/ObservableService";
import { authenticationService } from "../service/authentication.service";

const options = [
  { value: "de", label: "Deutsch" },
  { value: "en", label: "English" },
  { value: "fr", label: "Français" },
];

class MainComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timerVisibility: false,
      triesVisibility: false,
      professionVisibility: false,
    };
  }

  /**
   * The componentDidMount method subscribes to the messageService and listenes to events of the observable.
   */
  componentDidMount() {
    this.subscription = messageService.getMessage().subscribe((message) => {
      if (message.value === "timerShow") {
        this.setState({ timerVisibility: true });
      } else if (message.value === "timerHide") {
        this.setState({ timerVisibility: false });
      } else if (message.value === "triesShow") {
        this.setState({ triesVisibility: true });
      } else if (message.value === "triesHide") {
        this.setState({ triesVisibility: false });
      } else if (message.value === "professionShow") {
        if (this.props.currentProfession !== "other") {
          this.setState({ professionVisibility: true });
        }
      } else if (message.value === "professionHide") {
        this.setState({ professionVisibility: false });
      }
    });
  }

  /**
   * The componentWillMount unsubscribes from the messageService if subscription is not null
   */
  componentWillMount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  /**
   * This method calls the parent MasterComponent and passes the time from its child TimeComponent
   * @param {} time time from timeComponent at the moment it is called in seconds
   */
  updateTime(time) {
    this.props.updateTime(time);
  }

  /**
   * This method calls the parent MasterComponent and passes the tries from its child TriesComponent
   * @param {} tries tries from TriesComponent at the moment it is called in number
   */
  updateTries(tries) {
    this.props.updateTries(tries);
  }

  /**
   * This method navigates through the App. It takes the next Page and sends it to the MasterComponent.
   * @param {*} nextPage page that should be navigated to.
   */
  changePage(nextPage) {
    this.props.handler(nextPage);
  }

  /**
   * This method controls the Dropdown menu in the Main Component. It takes the selected value from the menu and then changes to the desired language.
   * @param {*} e event value which is selected in the options dropdown
   */
  handleChange = (e) => {
    i18next.changeLanguage(e.value);
  };

  render() {
    const { t } = this.props;
    if (i18next && i18next.languages) {
      var defaultLanguage = options.filter(
        (o) => o.value === i18next.languages[0]
      );
    }

    return (
        <Navbar>
          <Navbar.Brand style={{color:"white"}}>
            <b>{t("sbb_memory")}</b>
            <br />
            {t("sbb_memory_subtitle")}
          </Navbar.Brand>
          <Nav>
            {!this.state.timerVisibility ? (
              <Button
                onClick={() => {
                  this.changePage("menu");
                }}
                className="button"
              >
                {t("menu")}
              </Button>
            ) : null}
            {this.props.currentPage !== "game" ? (
              <Select
                id="selecter"
                placeholder={t("select_placeholder")}
                defaultValue={defaultLanguage}
                onChange={this.handleChange}
                options={options}
              />
            ) : null}
          </Nav>
          <Nav>
            {this.state.timerVisibility ? (
              <div>
                <Navbar.Brand style={{color:"white", marginLeft:"10vh"}}>{t("time")}</Navbar.Brand>
                <TimerComponent
                  updateTime={(time) => {
                    this.updateTime(time);
                  }}
                />
              </div>
            ) : null}
          </Nav>
          <Nav>
            {this.state.triesVisibility ? (
              <div>
                <Navbar.Brand style={{color:"white", marginLeft:"10vh"}}>{t("tries")}</Navbar.Brand>{" "}
                <TriesComponent
                  updateTries={(tries) => {
                    this.updateTries(tries);
                  }}
                />
              </div>
            ) : null}
          </Nav>
          <div class="placeholder">
            {this.props.currentPage === "settings" ? (
              <Button
                onClick={() => {
                  this.changePage("loginAdmin");
                }}
                className="button"
              >
                {t("admin")}
              </Button>
            ) : null}
            {this.props.currentPage === "admin" ? (
              <Button
                className="button"
                onClick={() => {
                  authenticationService.logout();
                  this.changePage("menu");
                }}
              >
                {t("logout")}
              </Button>
            ) : null}
            {this.state.professionVisibility &&
            this.props.currentProfession !== "other" ? (
              <p class="profession">
                {t(`card.${this.props.currentProfession}.content.name`)}
              </p>
            ) : null}
          </div>
        </Navbar>
    );
  }
}
export default withTranslation()(MainComponent);
