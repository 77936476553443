// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `circle {
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
}

.title {
    color: #dc0000;
    font-weight: bold;
    margin-left: 5;
}

.text {
    color: #dc0000;
    font-weight: bold;
    margin-left: 5;
}`, "",{"version":3,"sources":["webpack://./src/styles/ProgressRingStyle.css"],"names":[],"mappings":"AAAA;IACI,mCAAmC;IACnC,yBAAyB;IACzB,yBAAyB;AAC7B;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,cAAc;AAClB","sourcesContent":["circle {\n    transition: stroke-dashoffset 0.35s;\n    transform: rotate(-90deg);\n    transform-origin: 50% 50%;\n}\n\n.title {\n    color: #dc0000;\n    font-weight: bold;\n    margin-left: 5;\n}\n\n.text {\n    color: #dc0000;\n    font-weight: bold;\n    margin-left: 5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
