// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#selecter {
    min-width: 10vw;
    margin: 1.5vh;
}

.navbar {
    background-color: rgb(169, 177, 195);
    color: black;
    position: sticky;
    top: 0;
    position: -webkit-sticky;
}

.span.navbar-brand {
    color: #dc0000;
}

.navigation-button {
    background: #DC0000;
    font-weight: bold;
    height: 10vh;
    width: 80vw;
    font-size: 5vh;
}

.navigation-button:hover {
    background: white;
    color: #DC0000;
    font-weight: bold;
    height: 10vh;
    width: 80vw;
    font-size: 5vh;
}

.login-button {
    background: #dc0000;
    height: 5vh;
    width: 10vw;
    font-size: 3vh;
    color: white;
    float: right;
}


.profession {
    color: white;
    font-size: large;
    font-weight: bold;
    margin-right: 1vw;
}

.placeholder {
    min-width: 25vw;
}

`, "",{"version":3,"sources":["webpack://./src/styles/MainStyle.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,oCAAoC;IACpC,YAAY;IACZ,gBAAgB;IAChB,MAAM;IACN,wBAAwB;AAC5B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,mBAAmB;IACnB,iBAAiB;IACjB,YAAY;IACZ,WAAW;IACX,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,cAAc;IACd,iBAAiB;IACjB,YAAY;IACZ,WAAW;IACX,cAAc;AAClB;;AAEA;IACI,mBAAmB;IACnB,WAAW;IACX,WAAW;IACX,cAAc;IACd,YAAY;IACZ,YAAY;AAChB;;;AAGA;IACI,YAAY;IACZ,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB","sourcesContent":["#selecter {\n    min-width: 10vw;\n    margin: 1.5vh;\n}\n\n.navbar {\n    background-color: rgb(169, 177, 195);\n    color: black;\n    position: sticky;\n    top: 0;\n    position: -webkit-sticky;\n}\n\n.span.navbar-brand {\n    color: #dc0000;\n}\n\n.navigation-button {\n    background: #DC0000;\n    font-weight: bold;\n    height: 10vh;\n    width: 80vw;\n    font-size: 5vh;\n}\n\n.navigation-button:hover {\n    background: white;\n    color: #DC0000;\n    font-weight: bold;\n    height: 10vh;\n    width: 80vw;\n    font-size: 5vh;\n}\n\n.login-button {\n    background: #dc0000;\n    height: 5vh;\n    width: 10vw;\n    font-size: 3vh;\n    color: white;\n    float: right;\n}\n\n\n.profession {\n    color: white;\n    font-size: large;\n    font-weight: bold;\n    margin-right: 1vw;\n}\n\n.placeholder {\n    min-width: 25vw;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
