import "../styles/GameStyle.css";

import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import "bootstrap/dist/css/bootstrap.min.css";
import cardCover from '../res/cover.png'
import { messageService } from '../service/ObservableService';
import * as _ from "lodash";
import i18next from "i18next";
import data from "../data/cards.json"

class GameComponent extends Component {

  constructor(props) {
    super(props);

    const categories = this.props.gameCategories;
    const cardAmount = this.getCardAmount(this.props.gameMode);

    const cardsLeft = data.cardsLeft;
    const cardsRight = data.cardsRight;

    var cardsArray = [];
    var cardsArrayLeft = cardsLeft;
    const cardsArrayRight = [];
    const cardFiller = cardsLeft.filter((c => c.profession === 'other'));
    const activeCategories = Object.keys(categories).filter(k => categories[k]);

    // only one subId per profession
    cardsArrayLeft = cardsArrayLeft.filter(c => activeCategories.includes(c.profession));
    const subArrayLeft = cardsArrayLeft.splice(0,cardsArrayLeft.length / 2);
    const subArrayRight = cardsArrayLeft;
    let randomNumber = Math.floor(Math.random() * activeCategories.length);
    subArrayLeft.splice(0, randomNumber);
    subArrayRight.splice(randomNumber,activeCategories.length)
    cardsArrayLeft = _.concat(subArrayLeft,subArrayRight);

    // not more than cardAmount / 2
    cardsArrayLeft = this.getNElements(cardsArrayLeft, cardAmount / 2);

    if (cardsArrayLeft.length < cardAmount / 2) {
      const fillers = this.getNElements(cardFiller, (cardAmount - cardsArrayLeft.length) / 2);
      cardsArrayLeft = _.concat(cardsArrayLeft, fillers);
    }
    cardsArrayLeft = this.getNElements(cardsArrayLeft, cardAmount / 2);

    // match with cards of the right side
    cardsRight.forEach(cardRight => {
      for(let i = 0; i < cardsArrayLeft.length; i++) {
        if (cardRight.id === cardsArrayLeft[i].id && cardRight.subId === cardsArrayLeft[i].subId) {
          cardsArrayRight.push(cardRight);
        }
      }
    })
    cardsArray = _.shuffle(_.concat(cardsArrayLeft, cardsArrayRight));

    const cardsObject = cardsArray.map(c => {
      return {
        id: c.id, subId: c.subId, locked: false, imgSource: cardCover, category: c.profession, content: c[`imgSource${i18next.language.toUpperCase()}`], flippable: true, matched: false
      }
    });
    const cardsState = _.zipObject(Array.from({ length: cardAmount }, (z, i) => i + 1), cardsObject);

    // game state
    this.state = {
      imgSource: cardCover,
      cardAmount: 0,
      tries: 0,
      categories: this.props.gameCategories,

      flippedBefore: null,

      cards: cardsState,
      review: false
    }
  }

  /**
  * 
  *                  - - - COMPONENT CONTROL - - - 
  * 
  */
  componentDidMount() {
    this.subscription = messageService.getMessage().subscribe((message) => {
      if (message.value === 'XXX') {
      }
    });
  }

  componentWillMount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  changePage(nextPage) {
    this.props.handler(nextPage);
  }

  /**
   * The endGame method send a message to the TimerComponent to stop the timer and changes the page to FromComponent.
   */
  endGame() {
    // timerstate 
    this.props.sendMessage('timerSend');
    // tries 
    this.props.sendMessage('triesSend');
    this.changePage('form');
  }

  /**
  * 
  *                  - - - GAME CONTROL - - - 
  * 
  */

  /**
   * The method takes an array and a number n. It shuffles the array and returns the first n elements from the shuffled array
   * @param {*} array 
   * @param {*} n n elements returned
   * @returns n random elements from array.
   */
  getNElements = (array, n) => {
    return _.shuffle(array).slice(0, n);
  }

  /**
   * This Getter returns current gameMode
   * @returns current gameMode selected in GameModeComponent which is either easy, medium or hard. none if not selected
   */
  getGameMode() {
    return this.props.gameMode;
  }

  /**
   * This method returns the selected gameCategories from the MasterComponent.
   * @returns gameCategories selected in SettingsComponent
   */
  getCategories() {
    return this.props.gameCategories;

  }

  getCardAmount(gameMode) {
    switch (gameMode) {
      case 'easy':
        return 12;
      case 'medium':
        return 16;
      case 'hard':
        return 20;
      default:
        return 12;
    }
  }

  /**
   * Checks if Game is over.
   * @returns true if game is over, else false
   */
  isGameOver() {
    const keys = Object.keys(this.state.cards);
    for (var key in keys) {
      if (!this.state.cards[keys[key]].matched) {
        return false;
      }
    }
    return true;
  }

  /**
   * 
   *                  - - - INGAME - - - 
   * 
   */

  /**
   * The flip method changes the status of the respective card by id as soon as it has been triggered by an onClick event. 
   * @param {*} id specific card id passed with the onClick event
   */
  flip = (id) => {
    this.props.setCurrentProfession(this.state.cards[id].category);
    this.props.sendMessage('professionShow');
    let currentCard = this.state.cards[id];
    let cards = this.state.cards;
    if (currentCard.flippable && !currentCard.matched && !this.state.review) {
      currentCard.flippable = false;
      currentCard.imgSource = `${process.env.PUBLIC_URL}/images/${currentCard.content}`;
      cards[id] = currentCard;
      if (this.state.flippedBefore) {
        if (currentCard.id === cards[this.state.flippedBefore].id) {
          cards[id].matched = true;
          cards[this.state.flippedBefore].matched = true;
          if (this.getGameMode() === 'hard') {
            cards = _.shuffle(cards);
          }
          this.props.sendMessage('professionHide');
          this.setState({ cards: cards, flippedBefore: null });
        } else {
          this.setState({ cards: cards, review: true, tries: this.state.tries + 1 });
          this.props.sendMessage('triesIncrease');
        }
      } else {
        this.setState({ cards: cards, flippedBefore: id });
      }

      if (this.isGameOver()) {
        this.endGame();
      }
    }
  }

  /**
   * This method resets the currentlyFlipped states after one round
   */
  resetCurrentlyFlipped() {
    this.props.sendMessage('professionHide');
    const cards = this.state.cards;
    Object.keys(cards).forEach(cid => {
      const card = cards[cid];
      if (!card.matched) {
        card.imgSource = cardCover;
        card.flippable = true;
        cards[cid] = card;
      }
    })
    this.setState({ cards: cards, review: false, flippedBefore: null });
  }

  /** The render() method is responsible for all elements that are visible to the user, i.e. the cards. 
   * @returns <div> with all elements inside
   */
  render() {
    if (this.state.review) {
      setTimeout(() => { this.resetCurrentlyFlipped() }, 1000)
    }
    const containerClass = this.getGameMode() === 'easy' ? "grid-container-small" : this.getGameMode() === 'medium' ? "grid-container-medium" : "grid-container-big";
    const cardClass = this.getGameMode() === 'easy' ?  "cardSize-small" : this.getGameMode() === 'medium' ? "cardSize-medium" : "cardSize-big";
    const fields = Object.keys(this.state.cards).map((key, i) => {
      return (
        <>
          <div class="grid-item1" onClick={() => this.flip(key)}>
            <img class={cardClass} src={this.state.cards[key].imgSource} alt="card" />
          </div>
        </>
      );
    });
    return (
      <div class="game-wrapper">
        <div class={containerClass}>
          {fields}
        </div>
      </div>
    );
  }
}
export default withTranslation()(GameComponent);
