// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
    width: 100vw;
    height: 100vh;
    background-color:rgb(169, 177, 195);
    justify-content: center;
    align-items: center;
}

th {
    background-color: #dc0000;
    color: white;
    text-align: center;
}

td {
    text-align: center;
    font-weight: bolder;
}

tr:nth-child(even) {
    background-color: #dc000048;
}

tr:nth-child(odd) {
    background-color: #dc0000a6;
}

.login-form {
    justify-content:center;
    align-items: center;
    margin-left:25%;
    width: 50vw;
    height:10vh;
    color: white;
}

`, "",{"version":3,"sources":["webpack://./src/styles/LoginAdminStyle.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,mCAAmC;IACnC,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;IACf,WAAW;IACX,WAAW;IACX,YAAY;AAChB","sourcesContent":[".login-container {\n    width: 100vw;\n    height: 100vh;\n    background-color:rgb(169, 177, 195);\n    justify-content: center;\n    align-items: center;\n}\n\nth {\n    background-color: #dc0000;\n    color: white;\n    text-align: center;\n}\n\ntd {\n    text-align: center;\n    font-weight: bolder;\n}\n\ntr:nth-child(even) {\n    background-color: #dc000048;\n}\n\ntr:nth-child(odd) {\n    background-color: #dc0000a6;\n}\n\n.login-form {\n    justify-content:center;\n    align-items: center;\n    margin-left:25%;\n    width: 50vw;\n    height:10vh;\n    color: white;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
