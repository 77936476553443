// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../res/background.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-wrapper {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center center fixed;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    display: grid;
}

.menu-content {
    width: 100vw;
    height: 80vh;
    justify-content: center;
    align-items: center;
    display: grid;

}

.navigation-button {
    width: 80vw;
    height: 10vh;
}`, "",{"version":3,"sources":["webpack://./src/styles/MenuStyle.css"],"names":[],"mappings":"AAAA;IACI,iFAAsE;IACtE,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;;AAEjB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".menu-wrapper {\n    background: url(\"../res/background.jpg\") no-repeat center center fixed;\n    width: 100vw;\n    height: 100vh;\n    background-size: cover;\n    overflow: hidden;\n    justify-content: center;\n    align-items: center;\n    display: grid;\n}\n\n.menu-content {\n    width: 100vw;\n    height: 80vh;\n    justify-content: center;\n    align-items: center;\n    display: grid;\n\n}\n\n.navigation-button {\n    width: 80vw;\n    height: 10vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
