import "./App.css";
import { BrowserRouter, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import MasterComponent from "./components/MasterComponent";
import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import AdminComponent from "./components/AdminComponent";
import LoginAdminComponent from "./components/LoginAdminComponent";
import FormComponent from "./components/FormComponent";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let path = process.env.PUBLIC_URL;
    const imgs = [
      path + "/images/de/aab_description.png",
      path + "/images/de/aab_name.png",
      path + "/images/de/am_description.png",
      path + "/images/de/am_name.png",
      path + "/images/de/et_description.png",
      path + "/images/de/et_name.png",
      path + "/images/de/fk_description.png",
      path + "/images/de/fk_name.png",
      path + "/images/de/inf_description.png",
      path + "/images/de/inf_name.png",
      path + "/images/de/kt_description.png",
      path + "/images/de/kt_name.png",
      path + "/images/de/kv_description.png",
      path + "/images/de/kv_name.png",
      path + "/images/de/log_description.png",
      path + "/images/de/log_name.png",
      path + "/images/de/mm_description.png",
      path + "/images/de/mm_name.png",
      path + "/images/de/pm_description.png",
      path + "/images/de/pm_name.png",

      path + "/images/en/aab_description.png",
      path + "/images/en/aab_name.png",
      path + "/images/en/am_description.png",
      path + "/images/en/am_name.png",
      path + "/images/en/et_description.png",
      path + "/images/en/et_name.png",
      path + "/images/en/fk_description.png",
      path + "/images/en/fk_name.png",
      path + "/images/en/inf_description.png",
      path + "/images/en/inf_name.png",
      path + "/images/en/kt_description.png",
      path + "/images/en/kt_name.png",
      path + "/images/en/kv_description.png",
      path + "/images/en/kv_name.png",
      path + "/images/en/log_description.png",
      path + "/images/en/log_name.png",
      path + "/images/en/mm_description.png",
      path + "/images/en/mm_name.png",
      path + "/images/en/pm_description.png",
      path + "/images/en/pm_name.png",

      path + "/images/fr/aab_description.png",
      path + "/images/fr/aab_name.png",
      path + "/images/fr/am_description.png",
      path + "/images/fr/am_name.png",
      path + "/images/fr/et_description.png",
      path + "/images/fr/et_name.png",
      path + "/images/fr/fk_description.png",
      path + "/images/fr/fk_name.png",
      path + "/images/fr/inf_description.png",
      path + "/images/fr/inf_name.png",
      path + "/images/fr/kt_description.png",
      path + "/images/fr/kt_name.png",
      path + "/images/fr/kv_description.png",
      path + "/images/fr/kv_name.png",
      path + "/images/fr/log_description.png",
      path + "/images/fr/log_name.png",
      path + "/images/fr/mm_description.png",
      path + "/images/fr/mm_name.png",
      path + "/images/fr/pm_description.png",
      path + "/images/fr/pm_name.png",

      path + "/images/filler/1/campus.png",
      path + "/images/filler/1/turm.png",

      path + "/images/filler/2/hochhaus1.png",
      path + "/images/filler/2/hochhaus2.png",

      path + "/images/filler/3/beamer.png",
      path + "/images/filler/3/leinwand.png",

      path + "/images/filler/4/auto.png",
      path + "/images/filler/4/ladestation.png",

      path + "/images/filler/5/bus.png",
      path + "/images/filler/5/zug.png",

      path + "/images/filler/6/badge.png",
      path + "/images/filler/6/timetool.png",

      path + "/images/filler/7/becher.png",
      path + "/images/filler/7/kaffeemaschine.png",

      path + "/images/filler/8/pausenraum.png",
      path + "/images/filler/8/selecta.png",

      path + "/images/filler/9/personalrestaurant.png",
      path + "/images/filler/9/screen.png",

      path + "/images/filler/10/basketball.png",
      path + "/images/filler/10/fitness.png",

      path + "/images/filler/11/first-aid.png",
      path + "/images/filler/11/helm.png",

      path + "/images/filler/12/lift.png",
      path + "/images/filler/12/liftknoepfe.png",

      path + "/images/profession/aab.png",
      path + "/images/profession/am.png",
      path + "/images/profession/et.png",
      path + "/images/profession/fk.png",
      path + "/images/profession/inf.png",
      path + "/images/profession/kt.png",
      path + "/images/profession/kv.png",
      path + "/images/profession/log.png",
      path + "/images/profession/mm.png",
      path + "/images/profession/pm.png",

      path + "/images/special/aab.png",
      path + "/images/special/am.png",
      path + "/images/special/et.png",
      path + "/images/special/fk.png",
      path + "/images/special/inf.png",
      path + "/images/special/kt.png",
      path + "/images/special/kv.png",
      path + "/images/special/log.png",
      path + "/images/special/mm.png",
      path + "/images/special/pm.png",

      path + "/images/icon_red.png",

      "res/background-blur.png",
      "res/background-game-blur.png",
      "res/background-game.jpg",
      "res/background.jpg",
      "res/cover.png",
      "res/icon_grey.png",
      "res/icon_red.png",
    ];
    cacheImages(imgs);
  }, []);

  const cacheImages = async (srcArray) => {
    const promises = await srcArray.map((src) => {
      return new Promise(function (resolve, reject) {
        const img = new Image();

        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      });
    });
    await Promise.all(promises);
    setTimeout(function () {
      setIsLoading(false);
    }, 3000);
  };

  return (
    <>
      {isLoading ? (
        <div class="loader">
          <img
            alt="logo"
            src={process.env.PUBLIC_URL + "res/icon_red.png"}
            height="300vh"
            width="300vw"
          />
          <ReactLoading type={"balls"} color={"#fff"} />
        </div>
      ) : (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<MasterComponent />} />
            <Route path="/form" element={<FormComponent />} />
          </Routes>
        </BrowserRouter>
      )}
    </>
  );
}
export default App;
