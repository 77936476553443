import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { withTranslation } from "react-i18next";
import disableScroll from "disable-scroll";

import { messageService } from "../service/ObservableService";
import io from "socket.io-client";

// import all components
import MainComponent from "./MainComponent";
import MenuComponent from "./MenuComponent";
import SettingsComponent from "./SettingsComponent";
import GameModeComponent from "./GameModeComponent";
import StatisticsComponent from "./StatisticsComponent";
import GameComponent from "./GameComponent";
import FormComponent from "./FormComponent";
import AdminComponent from "./AdminComponent";
import LoginAdminComponent from "./LoginAdminComponent";

class MasterComponent extends Component {
  socket = io(process.env.REACT_APP_SOCKET_URL, {
    reconnection: true,
    rejectUnauthorized: false,
    namespace: "socket",
    path: "/api/socket/",
  });
  constructor(props) {
    super(props);
    disableScroll.on();
    // GameStates
    this.state = {
      currentPage: "menu",
      lastPage: null,
      gameMode: null,
      tempImage: null,
      gameCategories: {
        aab: true,
        am: true,
        et: true,
        fk: true,
        inf: true,
        kv: true,
        kt: true,
        log: true,
        mm: true,
        pm: true,
      },
      gameTime: null,
      gameTries: null,
    };
    this.socket.on("connect", () => {
      console.log("connected");
    });
    this.socket.on("connect_error", (err) => {
      console.log(err.stack);
    });
  }

  /**
   * This method sends a message through the OberservableService, so that the timer gets visible in MainComponent.
   * @param {*} message message that should be send
   */
  sendMessage(message) {
    messageService.sendMessage(message);
  }

  /**
   * This method navigates through the app.
   * @param {*} nextPage page that should be visible
   */
  handler = (nextPage) => {
    this.setState({
      currentPage: nextPage,
    });
    if (nextPage === "game") {
      // set timer to visible
      this.sendMessage("timerShow");
      // set tries to visible
      this.sendMessage("triesShow");
    }
  };

  /**
   * This method can be called from MainComponent to update the gameTime state when the game is finished.
   * @param {*} time time that was needed to finish the game
   */
  updateTime = (time) => {
    this.setState({
      gameTime: time,
    });
  };

  /**
   * This method can be called from SettingsComponent to update the gameCategories state which is necessary to initialize the game.
   * @param {*} categories categories which are used in the current game
   */
  updateCategories = (categories) => {
    this.setState({
      gameCategories: categories,
    });
  };

  /**
   * This method can be called from GameModeComponent and is a setter for the gameMode state in MasterComponent
   * @param {*} mode mode which is selected in GameModeComponent
   */
  updateGameMode = (mode) => {
    this.setState({
      gameMode: mode,
    });
  };

  /**
   * This method can be called from TriesComponent to update the gameTries state when the game is finished.
   * @param {*} tries tries that were needed to finish the game
   */
  updateTries = (tries) => {
    this.setState({
      gameTries: tries,
    });
  };

  /**
   * Getter for currentPage
   * @returns currentPage state
   */
  getCurrentPage = () => {
    return this.state.currentPage.toString;
  };

  /**
   * Set the currently flipped Profession
   * @param {*} profession
   */
  setCurrentProfession = (profession) => {
    this.setState({
      currentProfession: profession,
    });
  };

  render() {
    const page = this.state.currentPage;

    return (
      <div>
        <MainComponent
          handler={(nextPage) => {
            this.handler(nextPage);
          }}
          updateTime={(time) => {
            this.updateTime(time);
          }}
          updateTries={(tries) => {
            this.updateTries(tries);
          }}
          {...this.state}
        />
        {page === "menu" ? (
          <MenuComponent
            handler={(nextPage) => {
              this.handler(nextPage);
            }}
            getCurrentPage={() => this.getCurrentPage()}
          />
        ) : null}
        {page === "settings" ? (
          <SettingsComponent
            handler={(nextPage) => {
              this.handler(nextPage);
            }}
            updateCategories={(categories) => {
              this.updateCategories(categories);
            }}
            {...this.state}
          />
        ) : null}
        {page === "gameMode" ? (
          <GameModeComponent
            handler={(nextPage) => {
              this.handler(nextPage);
            }}
            updateGameMode={(mode) => {
              this.updateGameMode(mode);
            }}
            {...this.state}
          />
        ) : null}
        {page === "statistics" ? (
          <StatisticsComponent
            handler={(nextPage) => {
              this.handler(nextPage);
            }}
            socket={this.socket}
          />
        ) : null}
        {page === "game" ? (
          <GameComponent
            handler={(nextPage) => {
              this.handler(nextPage);
            }}
            sendMessage={(message) => {
              this.sendMessage(message);
            }}
            updateTries={(tries) => {
              this.updateTries(tries);
            }}
            setCurrentProfession={(profession) => {
              this.setCurrentProfession(profession);
            }}
            {...this.state}
          />
        ) : null}
        {page === "form" ? (
          <FormComponent
            handler={(nextPage) => {
              this.handler(nextPage);
            }}
            {...this.state}
            resetAllStates={this.resetAllStates}
            socket={this.socket}
          />
        ) : null}
        {page === "loginAdmin" ? (
          <LoginAdminComponent
            handler={(nextPage) => {
              this.handler(nextPage);
            }}
            {...this.state}
          />
        ) : null}
        {page === "admin" ? (
          <AdminComponent
            handler={(nextPage) => {
              this.handler(nextPage);
            }}
            {...this.state}
          />
        ) : null}
      </div>
    );
  }
}
export default withTranslation()(MasterComponent);
