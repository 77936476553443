// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type=checkbox] {
    transform: scale(2);
    margin: 1.5vh;
}

label {
    margin-left: 5vw;
    font-size: 2em;
}

#save-button {
    width: 50vw;
    height: 4vh;
}
.wrapper-settings {
    background-color: rgb(169, 177, 195);
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    display: grid;
    grid-template-rows: 5vh;
    bottom: 50vh;
}

`, "",{"version":3,"sources":["webpack://./src/styles/SettingsStyle.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,WAAW;AACf;AACA;IACI,oCAAoC;IACpC,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,uBAAuB;IACvB,YAAY;AAChB","sourcesContent":["input[type=checkbox] {\n    transform: scale(2);\n    margin: 1.5vh;\n}\n\nlabel {\n    margin-left: 5vw;\n    font-size: 2em;\n}\n\n#save-button {\n    width: 50vw;\n    height: 4vh;\n}\n.wrapper-settings {\n    background-color: rgb(169, 177, 195);\n    width: 100vw;\n    height: 100vh;\n    align-items: center;\n    justify-content: center;\n    display: grid;\n    grid-template-rows: 5vh;\n    bottom: 50vh;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
